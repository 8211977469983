/* eslint-disable no-nested-ternary */
import React from "react";
import { string, shape, arrayOf } from "prop-types";
import { TileCollection } from "../../CardCollection";
import { RCL as useDictionary } from "../../RCL";

const CityTilesConnector = ({ cities, locale }) => {
	// TODO - Update logic for retrieving base url
	let citySlug = "/en";

	if (locale === "fr-CA") {
		citySlug = "/fr";
	}
	const exploreMoreLabel = useDictionary({ searchKey: "explore-more" });

	const parsedCities = cities?.map(city => ({
		sys: {
			contentType: {
				sys: {
					id: "card",
				},
			},
		},
		id: city.contentful_id,
		variant: "primary",
		heading: city?.destinationName
			? city?.destinationCountry?.title
				? `${city?.destinationName}, ${city?.destinationCountry?.title}`
				: city?.destinationName
			: city?.destinationCountry?.title,
		image: city?.image || city?.imageCarousel?.images,
		link: {
			linkText: exploreMoreLabel,
			url: `${citySlug}/${city?.destinationCountry?.urlMappingSlug}/${city?.urlMappingSlug}`,
			target: "_self",
		},
	}));

	return <TileCollection items={parsedCities} itemsPerRow={4} />;
};

CityTilesConnector.propTypes = {
	cities: arrayOf(
		shape({
			destinationName: string,
			urlMappingSlug: string,
			destinationCountry: shape({
				title: string,
				urlMappingSlug: string,
			}),
			images: arrayOf(
				shape({
					title: string,
					cloudinaryAsset: arrayOf(
						shape({
							secure_url: string,
							width: string,
							height: string,
							raw_transformation: string,
							version: string,
							public_id: string,
							format: string,
						})
					),
				})
			),
		})
	),
	locale: string,
};

CityTilesConnector.defaultProps = {
	cities: [],
	locale: "en-CA",
};

export default CityTilesConnector;
export { CityTilesConnector };
